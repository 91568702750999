import React, { useContext } from "react"
import Helmet from "react-helmet"

import BannerSection from "../components/BannerSection"
import { TextSection, BackgroundSection } from "../components/Section"
import BannerContext from "../lib/context"



import VisionImage from '../assets/images/general/177.jpg'


import GroupImage from '../assets/images/church-interior/14-colour.jpg'


import BlankProfileImage from '../assets/images/blank-profile.png'
import PastorAfiImage from '../assets/images/team/afi1.jpg'
import JuanErzoImage from '../assets/images/team/Juan Erazo Deacon.jpg'
import MervellaFrancisImage from '../assets/images/team/Marvella Francis Deacon.jpg'
// import PaulineMaslineImage from '../assets/images/team/Pauline Masline.jpg'
// import DollyAjalaImage from '../assets/images/team/Dolly Ajala.jpg'
import CarolineImage from '../assets/images/team/Caroline.jpg'

import JohnOpondoImage from '../assets/images/team/Mr John Opondo.png'
import PaulineMaslineImage from '../assets/images/team/Mrs Pauline Maslin.png'
import OlasumboYatesImage from '../assets/images/team/Mrs Olasumbo Yates.png'
import YaaOwusuaImage from '../assets/images/team/Mrs Yaa Owusua.png'
// import CarolineImage from '../assets/images/team/Caroline.png'





const AboutUs = () => {
    const siteTitle = "Blackheath and Charlton Baptist Church - About Us"
    // const pageData = useLocalData( getStaticPageData, 'about-us-banners', 'about-us-banners' )
    const pageData = useContext( BannerContext )['about-us-banners']
    const { header, banners } = pageData || { header: null, banners: null }
    // const headerImages = useData( getAboutUsHeader )
    // const headerTitle = "About Us"
    const headerImages = header ? header.images : []
    const headerTitle = header ? header.title : 'About Us'
    const headerText = header ? header.text : ''

    const headerIcon = <span className="icon major"><i className="fas fa-users"></i></span>
    const headerButtons = [
        { url: "/about-us/#one", text: "Learn about us" }
    ]

    
    return (
        <div id="about-us-page">
            {/* <Header images={headerImages} headerText={headerText} headerTitle={headerTitle} tint={0.6} headerButtons={headerButtons} /> */}
            <Helmet title={siteTitle} />
{/* 
            <section data-aos="fade-up" id="who-we-are" className="main style1">
                <div className="grid-wrapper">
                    <div className="col-6">
                        <header className="major">
                            <h2>Who We Are</h2>
                        </header>
                        <p><strong>Blackheath and Charlton Baptist Church</strong> is a Christ-centred, 
                        Spirit led multicultural community  rooted in God’s love with a desire for encountering His presence and to see His will be done in our lives. 
                        </p>
                        <p> 
                            We are a welcoming church family with a heart of praise who always endeavours to let the gospel and example of Jesus Christ find 
                            expression our daily lives by showing our love for those we encounter and being a light in this world.
                        </p>
                    </div>
                    <div className="col-6">
                        <span className="image fit"><img src={GroupImage} alt="" /></span>
                    </div>
                </div>
            </section> */}

            <BackgroundSection 
                id="who-we-are"
                style="style1"
                title="Who We Are"
                tint={0.3}
                blur={0}
                description={`<em>Blackheath and Charlton Baptist Church</em> is a Christ-centred, 
                        Spirit led multicultural community  rooted in God’s love with a desire for encountering His presence and to see His Will be done in our lives.
                        
                        We are a welcoming church family with a heart of praise who always endeavours to let the gospel and example of Jesus Christ find expression in our daily lives by showing our love for those we encounter and being a light in this world.`}
                background={[ GroupImage ]}
                alt

            />

            <TextSection 
                id="what-we-believe"
                style="style2 large"
                title="What We Believe"
                description={`We believe in the one true God, Jesus Christ our redeemer and Saviour and the manifesting power and gifts of the Holy Spirit.

                We believe that God is a Holy, Just and Righteous God and there no unrighteousness in Him.
                
                We believe in Jesus Christ as the only son of God who by His sacrificial act of love was crucified, died and was raised for the forgiveness of our sins and to reconcile humanity back to God. 
                It is by this love, action and grace that we are saved and not by human works or effort.
                
                We believe in the infallible and live Word of God that is the Holy Bible by which our faith grows and becomes active as we live righteous and sanctified lives.
                
                We believe in the indwelling, outpouring and continuous renewal work of the Holy Spirit that manifests God’s power through miracles. 
                This is the Spirit of truth that equips and guides us in the fulfilment of God’s purpose in our lives and His mission here on earth.`}
                // background={[ BelieveImage2 ]}
                

            />

            <BackgroundSection 
                id="vision"
                style="style1 large"
                title="Vision"
                background={[ VisionImage ]}
                description={` Our family believes in living our lives as a testimony to Jesus Christ by living life to its fullest, 
                            loving another as Christ loves us, equipping and empowering others with the Word of God to live transformative and faith-filled lives, and offering our lives in service to those in need within our church, locally and globally.`}
                
                tint={0.35}

            />


            <section data-aos="fade-up" id="our-team" className="main style1 special">
                    <div className="col-12">
                        <header className="major">
                            <h2>Our Team</h2>
                        </header>
                    </div>
                    
                    <div className='team-section'>
                        <div className="team-member">
                            <span className="image fit rounded"><img src={PastorAfiImage} alt="" /></span>
                            <h3>Rev. Afi Kirk</h3>
                            <p><strong>Minister</strong></p>
                        </div>
                        <div className="team-member">
                            <span className="image fit rounded"><img src={BlankProfileImage} alt="" /></span>
                            <h3>Tunde Omotosho</h3>
                            <p><strong>Treasurer</strong></p>
                        </div>
                        <div className="team-member">
                            <span className="image fit rounded"><img src={CarolineImage} alt="" /></span>
                            <h3>Caroline Ntinyari</h3>
                            <p><strong>Acting Administrator</strong></p>
                        </div>
                        {/* <div className="team-member">
                            <span className="image fit rounded"><img className="top" src={DollyAjalaImage} alt="" /></span>
                            <h3>Dolly Ajala</h3>
                            <p><strong>Youth Leader</strong></p>
                        </div> */}
                        {/* <div className="team-member">
                            <span className="image fit rounded"><img className='juan-erazo-img' src={JuanErzoImage} alt="" /></span>
                            <h3>Juan Erazo</h3>
                            <p><strong>Deacon</strong></p>
                        </div> */}
                        {/* <div className="team-member">
                            <span className="image fit rounded"><img src={PaulineMaslineImage} alt="" /></span>
                            <h3>Pauline Maslin</h3>
                            <p><strong>Deacon</strong></p>
                        </div> */}
                        {/* <div className="team-member">
                            <span className="image fit rounded"><img  src={MervellaFrancisImage} alt="" /></span>
                            <h3>Marvella Francis</h3>
                            <p><strong>Deacon</strong></p>
                        </div>
                         */}

                        <div className="team-member">
                            <span className="image fit rounded"><img src={JohnOpondoImage} alt="" /></span>
                            <h3>John Opondo</h3>
                            <p><strong>Deacon</strong></p>
                        </div>
                        <div className="team-member">
                            <span className="image fit rounded"><img  src={OlasumboYatesImage} alt="" /></span>
                            <h3>Olasumbo Yates</h3>
                            <p><strong>Deacon</strong></p>
                        </div>
                        <div className="team-member">
                            <span className="image fit rounded"><img src={PaulineMaslineImage} alt="" /></span>
                            <h3>Pauline Maslin</h3>
                            <p><strong>Deacon</strong></p>
                        </div>
                        <div className="team-member">
                            <span className="image fit rounded"><img  src={YaaOwusuaImage} alt="" /></span>
                            <h3>Yaa Owusua</h3>
                            <p><strong>Deacon</strong></p>
                        </div>
                        
                        
                    </div>
                    

            </section>


{/* 
            <section data-aos="fade-up" id="our-team" className="main style1 special">
                <div className="grid-wrapper">
                    <div className="col-12">
                        <header className="major">
                            <h2>Our Team</h2>
                        </header>
                    </div>
                    
                    <div className="col-4">
                        <span className="image fit rounded"><img src={BlankProfileImage} alt="" /></span>
                        <h3>Rev. Afi Kirk</h3>
                        <p><strong>Minister</strong></p>
                    </div>
                    <div className="col-4">
                        <span className="image fit rounded"><img src={BlankProfileImage} alt="" /></span>
                        <h3>Tunde Omotosho</h3>
                        <p><strong>Treasurer</strong></p>
                    </div>
                    <div className="col-4">
                        <span className="image fit rounded"><img src={BlankProfileImage} alt="" /></span>
                        <h3>Juan Erazo</h3>
                        <p><strong>Deacon</strong></p>
                    </div>
                    <div className="col-4">
                        <span className="image fit rounded"><img src={BlankProfileImage} alt="" /></span>
                        <h3>Pauline Maslin</h3>
                        <p><strong>Deacon</strong></p>
                    </div>
                    <div className="col-4">
                        <span className="image fit rounded"><img src={BlankProfileImage} alt="" /></span>
                        <h3>Marvella Francis</h3>
                        <p><strong>Deacon</strong></p>
                    </div>
                    

                </div>
            </section> */}

            <BannerSection banners={banners} />
        </div>
    )
}

export default AboutUs